$avc-dark-green: #809C38;
$avc-light-green: #cceb7e;

.gbs-button {
    background-color: #809C38 !important;

    &:hover {
        background-color: #c2df7b !important;
        color:black;
      }
}


.card-header {
    background-color: white;
}

.card-title{
    color: #809C38;
}


.--ml-15 {
    margin-left: 15px;
}

.--ml-30 {
    margin-left: 30px;
}

.--ml-50 {
    margin-left: 50px;
}

.--ml-80 {
    margin-left: 80px;
}

.--ml-85 {
    margin-left: 85px;
}



.--mr-15 {
    margin-right: 15px;
}

.--mr-30 {
    margin-right: 30px;
}

.--mr-50 {
    margin-right: 50px;
}

.--mr-80 {
    margin-right: 80px;
}


.--mt-5 {
    margin-top: 5px;
}

.--mt-6 {
    margin-top: 6px;
}

.--mt-7 {
    margin-top: 7px;
}

.--mt-15 {
    margin-top: 15px;
}

.--mt-35 {
    margin-top: 35px;
}

.--mt-80 {
    margin-top: 80px;
}


.white {
    color: white
}

.black {
    color: black
}

.light-green {
    color: $avc-light-green
}

.dark-green {
    color: $avc-dark-green
}

.grey {
    color: #ececec;
}

.--black {
    color: black;
}

.dark-green-bg {
    background-color: $avc-dark-green
}

.--gray-bg {
    background-color: #ececec !important;
}

.--height-350 {
    height: 350px;
}

$footer-height: 90px;

.body {
    /* Make room for the footer */
    margin-top: 170px;
  }

.footer {
    /* Set the fixed height of the footer here */
    height: 100px;
  }

.error-text {
    font-size: 13px;
    color: red;
}

.--width-300 {
    width: 300px;
}

.dropdown-item.active,
.dropdown-item:active {
    color: black !important;
    background-color: white !important;
}

.--image {
    object-fit: cover;
}

.card-divider {
    border-right: 1px solid #ccc;
    height: 100px !important;
  }

.home-card {
    width: 183px;
}

.custom-card {
    border-radius: 1rem;
  }


.--navbar-custom {
    margin: 0;
    padding: 0;
    width: 100%;
}

.--bottom-row {
    margin-bottom: 20px;
}

.--copyright {
    color: gainsboro;
    font-size: 10px;
}

