.flex-row-start {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.flex-centered-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.--mt5 {
  margin-top: 5px !important;
}
.--mt10 {
  margin-top: 10px !important;
}

.--mt12 {
  margin-top: 12px !important;
}

.--mt15 {
  margin-top: 15px !important;
}


.--mt63 {
    margin-top: 63px !important;
  }


.--mb0 {
  margin-bottom: 0px !important;
}

.--mb12 {
  margin-bottom: 12px !important;
}



.--ml5{
    margin-left: 5px !important;
  }

.--ml10{
    margin-left: 10px !important;
  }

.--ml12{
  margin-left: 12px !important;
}

.--ml15{
  margin-left: 15px !important;
}

.--ml20 {
  margin-left: 20px !important;
}

.--ml25 {
  margin-left: 25px !important;
}


.--ml30 {
    margin-left: 25px !important;
  }

.--ml35 {
  margin-left: 35px !important;
}

.--ml70 {
  margin-left: 70px !important;
}

.--ml195 {
  margin-left: 195px !important;
}



.--mr10{
    margin-right: 10px !important;
  }

.--mr12{
  margin-right: 12px !important;
}

.--mr25 {
  margin-right: 25px !important;
}

.--mr35 {
  margin-right: 35px !important;
}

.--mr50 {
    margin-right: 50px !important;
  }

.--tc {
  text-align: center;
}